import BaseMethods from '../BaseMethods';
import {getData} from "@/api/v2/utils/DataHandler";

export default new (class extends BaseMethods {
    endpointRoute = '/api/v2/projects';

    async downloadTaskDocuments(id, params) {
        const url = `${this.endpointRoute}/${id}/download-task-documents`;

        return this.asJson().get(url, {params}).then(getData);
    }
})();
